<template>
<div class="content flex-row">
  <div class="greeting">
    <h1 class="greeting-text">
      <br>Выбери свой путь изучения <mark class="gold-mark">Python</mark>
    </h1>
    <router-link to="/road/python"><button class="button">Посмотреть карту</button></router-link>
  </div>
  <!-- <python-block></python-block> -->
</div>
<!-- <div class="content">
  <div class="paralax-img">
    <div class="selling-block">
      <ul>
        <li>Выбери IT-профессию</li>
        <li>Смотри <mark class="gold-mark">полную картину</mark> знаний для каждой специальности</li>
        <li><mark class="gold-mark">Сравнивай курсы</mark> в интернете</li>
        <li>Проверяй <mark class="gold-mark">покрытие курсов</mark> относительно необходимых знаний</li>
      </ul>
    </div>
  </div>
</div> -->
<!-- <div class="dark">
  <div class="content">
    <popular-professions
      :type="'profession'"
      :title="'Популярные профессии'"
      :root_path="''"
    ></popular-professions>
  </div>
</div> -->
</template>
<script>
import { useMeta } from 'vue-meta'
import Graph from '@/components/Graph.vue'
import PopularProfessions from '@/components/PopularProfessions.vue'
import PythonBlock from '@/components/PythonBlock.vue'

export default {
  setup() {
    useMeta({
      title: 'Yollection',
      description: 'Посмотрите популярные профессии и направления'
    })
  },
  components: {
    Graph,
    PopularProfessions,
    PythonBlock
  }
}
</script>
<style scoped>
.greeting {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
}
.content {
  background-image: url('../assets/python-wall.png');
  background-size: cover;
  border-bottom: 3px solid var(--dark-color);
}
.greeting-text {
  /* color: #354459; */
  text-align: left;
  margin-top: 50px;
  max-width: 500px;
  font-size: 42px;
  font-weight: normal;
  height: 100%;
  border-radius: 10px;
}
.gold-mark {
  background: none;
  padding-top: 5px;
  color: var(--light-color-selection);
}
.upper {
  text-transform: uppercase;
}
.flex-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.button {
  background-color: var(--light-color-selection);
  border: 1px solid var(--light-color-selection);
  color: var(--light-color);
  border-radius: 10px;
  padding: 14px;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.button:hover {
  background-color: var(--light-color);
  color: var(--dark-color);
}
.selling-block {
  color: var(--dark-color);
  font-size: 30px;
  text-align: left;
  margin-left: 20%;
  padding: 30px;
  background-color: rgba(245, 245, 245, 0.9);
}
.paralax-img {
  background-image: url("~@/assets/home-header.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}
.dark {
  background-color: var(--dark-color);
}
@media (max-width: 660px) {
  .selling-block {
    color: var(--dark-color);
    font-size: 30px;
    text-align: left;
    margin-left: 0;
    padding: 0;
    background-color: rgba(245, 245, 245, 0.9);
  }
}
</style>
