<template>
<router-link
  :to="'/' + type + '/'+item.slug"
  :style="hw"
  :id="type + item.id"
  class="inner-container">
    <div class="text-container">
        <div class="h2">
            <h3 :style="h3_style">{{ item.title }}</h3>
        </div>
        <div v-if="visible" class="p">
            <p :style="p_style">{{ item.description }}</p>
        </div>
    </div>
</router-link>
</template>

<script>
export default {
    name: 'tail',
    props: ['item', 'type'],
    data: function () {
        return {
            com_hw: 'height: 10px; width: 10px;',
            com_visible: true,
            max_width: 320,
            com_p_style: '',
            com_h3_style: ''
        }
    },
    computed: {
        hw: function () {
            return this.com_hw
        },
        visible: function () {
            return this.com_visible
        },
        p_style: function () {
            return this.com_p_style
        },
        h3_style: function () {
            return this.com_h3_style
        },
        color: function () {
            let colors = {
                programmer: '#778f00',
                devops: '#003787',
                'qa-engineer': '#8e4800'
            }
            return colors[this.item.slug]
        }
    },
    methods: {
        calculate_hw: function () {
            let width = window.innerWidth < 1440 ? window.innerWidth : 1440
            width += 10
            let max_size_count = Math.ceil(width / this.max_width)
            // let side = width / max_size_count - 20 - (6 - max_size_count) * (5 - max_size_count)
            let side = width / max_size_count - 50 + 11 * max_size_count - max_size_count * max_size_count
            this.com_hw = `height: ${side}px; width: ${side}px;`
            if (side < 140) {
                this.com_visible = false

            } else if (side < 180) {
                this.com_visible = true
                this.com_p_style = {
                    'font-size': '12px'
                }
                this.com_h3_style = {
                    'font-size': '14px'
                }
            } else if (side < 220) {
                this.com_p_style = {
                    'font-size': '14px'
                }
                this.com_h3_style = {
                    'font-size': '18px'
                }
            } else {
                this.com_p_style = {
                    'font-size': '16px'
                }
                this.com_h3_style = {
                    'font-size': '20px'
                }
            }
        }
    },
    mounted() {
        this.calculate_hw()
    },
    created() {
        window.addEventListener('resize', this.calculate_hw);
    },
    unmounted() {
        window.removeEventListener('resize', this.calculate_hw);
    },
}
</script>

<style scoped>
@import '../assets/constants.css';

.h2 {
    top: 0;
    border-radius: 10px 10px 0 0 ;
    z-index: 2;
    height: 150px;
    padding-left: 10px;
    padding-right: 10px;
    color: whitesmoke;
}
h3 {
    width: 100%;
}
.p {
    border-top: 1px solid var(--border-color);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: var(--light-background-color);
    z-index: 2;
    transition: all 0.2s ease-out;
}
p {
    margin: 10px;
}
img {
    position: absolute;
    border-radius: 10px;
    z-index: 1;
}
.text-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--light-color-selection);
    text-decoration: none;
    color: black;
    border-radius: 10px;
}
.inner-container {
    margin: 5px;
    position: relative;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    transition: border-color 0.2s ease-out;
}
.inner-container:hover {
    border-color: var(--light-color);
}
.inner-container:hover > .text-container > .p {
    height: 130%;
}
</style>