<template>
<div class="header">
    <div class="container">
      <div class="item">
        <router-link to="/">
        <img src="@/assets/logo.svg" width="120"/>
        </router-link>
      </div>
      <div class="item nav-bar" v-if="allowed_space">
        <router-link to="/">
        <p>Главная</p>
        </router-link>
        <router-link to="/road/python">
        <p>Карта</p>
        </router-link>
        <!-- <router-link to="/roads">
        <p>Карты</p>
        </router-link>
        <router-link to="/contacts">
        <p>Контакты</p>
        </router-link> -->
        <router-link to="/about">
        <p>О нас</p>
        </router-link>
      </div>
      <div v-else class="collapsed-nav-bar" @click="buttonClick">
        <div class="menu-collapse-button">
          Меню
          <!-- <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="var(--light-color-selection)" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
          </svg> -->
        </div>
      </div>
    </div>
</div>
<div class="collapsed-menu closed" id="menu-bar" v-if="!allowed_space">
  <router-link to="/">
  <p>Главная</p>
  </router-link>
  <router-link to="/road/python">
  <p>Карта</p>
  </router-link>
  <!-- <router-link to="/roads">
  <p>Карты</p>
  </router-link>
  <router-link to="/contacts">
  <p>Контакты</p>
  </router-link> -->
  <router-link to="/about">
  <p>О нас</p>
  </router-link>
</div>
</template>

<script>
export default {
    name: 'site-header',
    data: function () {
      return {
        allowed_space: true,
        collapsed_menu: true
      }
    },
    methods: {
    checkAllowedSpace: function () {
      if (window.innerWidth > 660) {
        this.allowed_space = true
      } else {
        this.allowed_space = false
      }
    },
    buttonClick: function () {
      this.collapsed_menu = !this.collapsed_menu
      // document.getElementById('menu-bar').style.display = this.collapsed_menu ? 'none' : 'flex'
      document.getElementById('menu-bar').classList.toggle('closed');
    }
  },
  mounted() {
    this.checkAllowedSpace()
  },
  created() {
    window.addEventListener('resize', this.checkAllowedSpace);
  },
  unmounted() {
    window.removeEventListener('resize', this.checkAllowedSpace);
  },
}
</script>

<style scoped>
@import '../assets/constants.css';
.header {
    width: 100%;
    background-color: var(--dark-color);
    left: 0;
    top: 0;
    border-bottom: 1px solid var(--border-color);
    z-index: 9999;
    display: inline-block;
}
.container {
  display: flex;
  /* justify-content: space-between; */
  padding-right: 30px;
  gap: 50px;
}
img {
    padding-left: 2em;
    padding-top: 10px;
}
.nav-bar {
  display: flex;
  gap: 20px;
  align-items: center;
}
.nav-bar > a {
  text-decoration: none;
  font-size: 20px;
  color: var(--light-color);
}
.nav-bar > a:hover {
  color: var(--light-color-selection);
}
@media (max-width: 660px) {
  .collapsed-nav-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
  }
  .menu-collapse-button {
    cursor: pointer;
    color: var(--light-color-selection);
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
  }
  .collapsed-menu {
    width: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    background-color: var(--dark-color);
    padding: 20px;
    max-height: 300px;
    transition: all 0.5s;
  }
  .closed {
    max-height: 0;
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
    overflow: hidden;
  }
  .collapsed-menu > a > p {
    margin: 0;
    padding: 8px;
    flex-basis: 50%;
    text-align: left;
    font-size: 20px;
  }
  .collapsed-menu > a {
    text-decoration: none;
    color: var(--light-color);
  }
  .collapsed-menu > a:hover {
    color: var(--light-color-selection);
  }
}
</style>
