<template>
<h1>{{ title }}</h1>
<div class="container" v-if="type=='direction'">
  <tail
    v-for="(profession, index) in items"
    class="item"
    :key="type + index"
    type="road"
    :item="profession"
  ></tail>
</div>
<div class="container" v-else>
  <tail
    v-for="(profession, index) in items"
    class="item"
    :key="type + index"
    :type="road"
    :item="profession"
  ></tail>
</div>
</template>

<script>
import Tail from '@/components/Tail.vue'
export default {
  components: { Tail },
  name: 'tail-grid',
  props: ['type', 'title', 'root_path'],
  data: function() {
    return {
      items: []
    }
  },
  mounted() {
    this.axios.get(`${this.site_url}/api/${this.root_path}${this.type}s/`).then(response => {
      this.items = response.data
    })
  }
}
</script>

<style scoped>
h1 {
  margin-top: 60px;
  text-align: left;
  margin-left: 30px;
}

.container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  /* width: calc(100% + 10px); */
  /* padding: 20px; */
}
</style>
