import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  },
  {
    path: '/road/:slug',
    name: 'Road',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Road.vue')
  },
  {
    path: '/direction/:slug',
    name: 'Direction',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Direction.vue')
  },
  {
    path: '/resource/:slug',
    name: 'Resource',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Resource.vue')
  },
  {
    path: '/profession/:slug',
    name: 'Profession',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Profession.vue')
  },
  {
    path: '/professions',
    name: 'Professions',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Professions.vue')
  },
  {
    path: '/block/:slug',
    name: 'Block',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Block.vue')
  },
  {
    path: '/blank/',
    name: 'Blank',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Blank.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page404',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Page404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
