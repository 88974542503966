<template>
<div class="code">
    <code>
        <pre>
class Student:

    def __init__(self, name, age):
        self.name = name
        self.age = age

    def __str__(self):
        return f‘{name}, {self.age}’

    def __repr__(self):
        return f’Student: &lt;{self.__str__()}>’
        </pre>
    </code>
</div>
<img class="image" src="@/assets/Python-logo.png" height="400px" width="400px">
</template>
<script>
export default {
    setup() {

    },
}
</script>
<style scoped>
.code {
    text-align: left;
}
.image {
    width: 200px;
    height: 200px;
}
</style>
