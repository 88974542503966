<template>
<div class="blank-for-footer"></div>
<div class="footer">
    <p class="footer-header">Yollection</p>
    <div class="footer-links">
        <a href="/about">О нас</a>
        <a href="/">Контакты</a>
        <a href="/">Вакансии</a>
        <a href="mailto:info@yollection.ru/">info@yollection.ru</a>
    </div>
    <p>Санкт-Петербург, Россия</p>
</div>
</template>
<script>
export default {
    setup() {

    },
}
</script>
<style scoped>
.blank-for-footer {
    min-height: 300px;
}
.footer {
    background-color: var(--dark-color);
    color: var(--light-color);
    margin: 0;
    padding: 20px;
    padding-top: 50px;
    text-align: left;
    position: absolute;
    bottom: 0;
    width: calc(100% - 40px);
}
.footer-header {
    font-size: 24px;
}
.footer-links > a {
    margin-right: 20px;
    color: var(--light-color);
    text-decoration: none;
}
.footer-links > a:hover {
    color: var(--light-color-selection);
}
</style>
