<template>
<div class="graph-container">
    <div id="graph"></div>
    <div class="layer"></div>
</div>
</template>
<script>
import ForceGraph3D from '3d-force-graph'
import SpriteText from 'three-spritetext'
export default {
    data: function () {
        return {
            myGraph: ForceGraph3D()
        }
    },
    methods: {
        reportWindowSize: function () {
            if (window.innerWidth < 660) {
                this.myGraph.width(window.innerWidth - 40)
            } else if (window.innerWidth < 1400) {
                this.myGraph.width(window.innerWidth / 3)
            } else {
                this.myGraph.width(1440 / 3)
            }
        },
    },
    created() {
    window.addEventListener('resize', this.reportWindowSize)
    let angle = 0;
    const distance = 1200;
    setInterval(() => {
        this.myGraph.cameraPosition({
            x: distance * Math.sin(angle),
            // y: distance * Math.sin(angle),
            z: distance * Math.cos(angle)
        });
        angle += Math.PI / 2880;
        }, 10);
    },
    mounted() {
        let elem = document.getElementById('graph')
        this.myGraph(elem)
        .graphData(
            {
    "nodes": [
        {
            "id": "profession1",
            "name": "Программист",
            "val": 0
        },
        {
            "id": "profession2",
            "name": "Тестировщик",
            "val": 1
        },
        {
            "id": "profession3",
            "name": "Сисадмин и DevOps",
            "val": 2
        },
        {
            "id": "direction1",
            "name": "Backend",
            "val": 3
        },
        {
            "id": "direction2",
            "name": "Frontend",
            "val": 4
        },
        {
            "id": "direction3",
            "name": "Android",
            "val": 5
        },
        {
            "id": "direction4",
            "name": "iOS",
            "val": 6
        },
        {
            "id": "direction5",
            "name": "Python",
            "val": 7
        },
        {
            "id": "direction6",
            "name": "DevOps",
            "val": 8
        },
        {
            "id": "direction7",
            "name": "Вадим",
            "val": 9
        },
        {
            "id": "chapter1",
            "name": "Интернет",
            "val": 10
        },
        {
            "id": "chapter1",
            "name": "Интернет",
            "val": 11
        },
        {
            "id": "chapter2",
            "name": "Базовый frontend",
            "val": 12
        },
        {
            "id": "chapter3",
            "name": "Общие знания ОС",
            "val": 13
        },
        {
            "id": "chapter4",
            "name": "Язык программирования",
            "val": 14
        },
        {
            "id": "chapter5",
            "name": "Система контроля версий",
            "val": 15
        },
        {
            "id": "chapter5",
            "name": "Система контроля версий",
            "val": 16
        },
        {
            "id": "chapter5",
            "name": "Система контроля версий",
            "val": 17
        },
        {
            "id": "chapter5",
            "name": "Система контроля версий",
            "val": 18
        },
        {
            "id": "chapter6",
            "name": "Реляционные БД",
            "val": 19
        },
        {
            "id": "chapter7",
            "name": "NoSQL",
            "val": 20
        },
        {
            "id": "chapter8",
            "name": "Больше про БД",
            "val": 21
        },
        {
            "id": "chapter9",
            "name": "API",
            "val": 22
        },
        {
            "id": "chapter10",
            "name": "Кэширование",
            "val": 23
        },
        {
            "id": "chapter11",
            "name": "Веб-безопасность",
            "val": 24
        },
        {
            "id": "chapter12",
            "name": "Тестирование",
            "val": 25
        },
        {
            "id": "chapter13",
            "name": "CI/CD",
            "val": 26
        },
        {
            "id": "chapter14",
            "name": "Принципы проектирования и разработки",
            "val": 27
        },
        {
            "id": "chapter15",
            "name": "Архитектурные паттерны",
            "val": 28
        },
        {
            "id": "chapter16",
            "name": "Поисковые машины",
            "val": 29
        },
        {
            "id": "chapter17",
            "name": "Брокеры сообщений",
            "val": 30
        },
        {
            "id": "chapter18",
            "name": "Контейнеризация и виртуализация",
            "val": 31
        },
        {
            "id": "chapter19",
            "name": "GraphQL",
            "val": 32
        },
        {
            "id": "chapter20",
            "name": "Графовые БД",
            "val": 33
        },
        {
            "id": "chapter21",
            "name": "Веб-сокеты",
            "val": 34
        },
        {
            "id": "chapter22",
            "name": "Веб-серверы",
            "val": 35
        },
        {
            "id": "chapter23",
            "name": "Масштабирование",
            "val": 36
        },
        {
            "id": "chapter24",
            "name": "Интернет",
            "val": 37
        },
        {
            "id": "chapter25",
            "name": "HTML",
            "val": 38
        },
        {
            "id": "chapter26",
            "name": "Базовые знания",
            "val": 39
        },
        {
            "id": "chapter27",
            "name": "Структуры данных и алгоритмы",
            "val": 40
        },
        {
            "id": "chapter28",
            "name": "Продвинутый Python",
            "val": 41
        },
        {
            "id": "chapter29",
            "name": "Менеджеры пакетов",
            "val": 42
        },
        {
            "id": "chapter30",
            "name": "Фреймворк",
            "val": 43
        },
        {
            "id": "chapter31",
            "name": "Тестирование",
            "val": 44
        },
        {
            "id": "chapter32",
            "name": "CSS",
            "val": 45
        },
        {
            "id": "chapter33",
            "name": "JavaScript",
            "val": 46
        },
        {
            "id": "chapter34",
            "name": "Система контроля версийdelete",
            "val": 47
        },
        {
            "id": "chapter35",
            "name": "Веб-безопасность",
            "val": 48
        },
        {
            "id": "chapter36",
            "name": "Менеджер пакетов",
            "val": 49
        },
        {
            "id": "chapter37",
            "name": "Языки программирования",
            "val": 50
        },
        {
            "id": "chapter38",
            "name": "Основы",
            "val": 51
        },
        {
            "id": "chapter39",
            "name": "Создание приложений",
            "val": 52
        },
        {
            "id": "chapter40",
            "name": "Архитектура CSS",
            "val": 53
        },
        {
            "id": "chapter41",
            "name": "Препроцессоры CSS",
            "val": 54
        },
        {
            "id": "chapter42",
            "name": "Системы организации задач",
            "val": 55
        },
        {
            "id": "chapter43",
            "name": "Сборщики модулей",
            "val": 56
        },
        {
            "id": "chapter44",
            "name": "Линтеры и форматтеры",
            "val": 57
        },
        {
            "id": "chapter45",
            "name": "Фреймворки",
            "val": 58
        },
        {
            "id": "chapter46",
            "name": "Современный CSS",
            "val": 59
        },
        {
            "id": "chapter47",
            "name": "Веб-компоненты",
            "val": 60
        },
        {
            "id": "chapter48",
            "name": "CSS-фреймворки",
            "val": 61
        },
        {
            "id": "chapter49",
            "name": "Тестирование",
            "val": 62
        },
        {
            "id": "chapter50",
            "name": "Прогрессивные веб-приложения",
            "val": 63
        },
        {
            "id": "chapter50",
            "name": "Прогрессивные веб-приложения",
            "val": 64
        },
        {
            "id": "chapter51",
            "name": "Проверка типов",
            "val": 65
        },
        {
            "id": "chapter52",
            "name": "Рендеринг на стороне сервера (SSR)",
            "val": 66
        },
        {
            "id": "chapter53",
            "name": "GraphQL",
            "val": 67
        },
        {
            "id": "chapter54",
            "name": "Создание статического сайта",
            "val": 68
        },
        {
            "id": "chapter55",
            "name": "Создание приложений",
            "val": 69
        },
        {
            "id": "chapter56",
            "name": "Надежные приложения",
            "val": 70
        }
    ],
    "links": []
}
        )
        .width(100)
        .height(400)
        .backgroundColor('whitesmoke')
        .enableNavigationControls(false)
        .linkColor(0xff8839)
        .showNavInfo(false)
        .nodeThreeObject(node => {
            const sprite = new SpriteText(node.name);
            sprite.material.depthWrite = false; // make sprite background transparent
            sprite.color = '#354459';
            sprite.textHeight = 20;
            return sprite;
        })
        .linkOpacity(1)
        this.reportWindowSize()
    }
}
</script>
<style scoped>
.graph-container {
    position: relative;
    padding: 0;
    margin: 0;
}
.layer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
</style>
