import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta'
import App from './App.vue'
import mitt from 'mitt';
import router from './router'
import axios from 'axios';
import VueAxios from 'vue-axios'
import VueYandexMetrika from 'vue-v3-yandex-metrika'

const emitter = mitt();

const app = createApp(App).use(createMetaManager())

app.use(router)
app.use(VueAxios, axios)
// app
app.use(VueYandexMetrika, {
    id: 86480470,
    router: router,
    env: 'production',
    options: {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true
    }
})

app.provide('axios', app.config.globalProperties.axios)
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.site_url = 'https://yollection.ru'
// app.config.globalProperties.site_url = 'http://127.0.0.1:8000'
app.mount('#app')

