<template>
  <site-header></site-header>
  <router-view/>
  <site-footer></site-footer>
</template>

<script>
import SiteHeader from '@/components/SiteHeader.vue'
import SiteFooter from '@/components/SiteFooter.vue'
export default {
  data: function () {
    return {
      comp_margin_left: 10,
    }
  },
  components: {
    SiteHeader,
    SiteFooter
  },
  computed: {
    margin_left: function () {
      return this.comp_margin_left
    }
  },
  methods: {
    calculate_margin_left: function () {
      if (window.innerWidth > 1400) {
        this.comp_margin_left = (window.innerWidth - 1400) / 2
      }
    }
  },
  mounted() {
    this.calculate_margin_left()
  },
  created() {
      window.addEventListener('resize', this.calculate_margin_left);
  },
  unmounted() {
      window.removeEventListener('resize', this.calculate_margin_left);
  },
}
</script>
<style>
@import 'assets/constants.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
  position: relative;
}

.content {
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}

.light-block {
  /* background: #003787; */
  background: #354459;
}

body {
  background-color: white;
  margin: 0;
}

</style>
